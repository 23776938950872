import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import storage from "../common/utils/storage";

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
if (isiOS && !window.entryLink) {
  window.entryLink = encodeURIComponent(window.location.href.split("#")[0]);
}

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

/*
isRoutes: true为必选项
*/
const routes = [
  {
    path: "/",
    redirect: 'auth',
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("@/views/auth/index")
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("@/views/home/index"),
    meta: {
      navShow: true
    }
  },
  {
    path: "/electronicPhoto",
    name: "ElectronicPhoto",
    component: () => import("@/views/ElectronicPhoto/index"),
    meta: {
      navShow: true
    }
  },
  {
    path: "/videoList",
    name: "VideoList",
    component: () => import("@/views/VideoWorks/list"),
    meta: {
      navShow: true,
      keepAlive: true
    }
  },
  {
    path: "/videoUpload",
    name: "VideoUpload",
    component: () => import("@/views/VideoWorks/upload"),
    meta: {
      navShow: false
    }
  },
  {
    path: "/videoDetail",
    name: "VideoDetail",
    component: () => import("@/views/VideoWorks/detail"),
    meta: {
      navShow: false
    }
  },
  {
    path: "/myList",
    name: "MyList",
    component: () => import("@/views/my/list"),
    meta: {
      navShow: true
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404/index"),
    meta: {
      navShow: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => { 
  // sessionStorage.setItem("UserInfo", JSON.stringify({
  //   Id: 963,
  //   NickName: '老王'
  // }));
  if(to.path != '/auth' && to.path != '/404') {
    const UserInfo = sessionStorage.getItem("UserInfo") ? JSON.parse(sessionStorage.getItem("UserInfo")) : null;
    if(UserInfo) {
      next()
    } else {
      next('/404')
    }
  } else {
    next()
  }
})

export default router;
