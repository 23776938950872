import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "amfe-flexible";
import storage from "./common/utils/storage";

import VuePageStack from 'vue-page-stack';
Vue.use(VuePageStack, { router });

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import Swiper2, {Navigation, Pagination,} from 'swiper'
Swiper2.use([Navigation, Pagination,]);
import VueAwesomeSwiper from "vue-awesome-swiper";
import 'swiper/swiper-bundle.css';
Vue.use(VueAwesomeSwiper);

import waterfall from 'vue-waterfall2'
Vue.use(waterfall)

import * as custom from './common/filters'
Object.keys(custom).forEach(key => { Vue.filter(key, custom[key])});

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

axios.interceptors.request.use(
  config => {
    // 测试环境
    // config.headers.common["token"] = 'F1E40932-B8EB-43ED-9546-B568310B2997';
    // qa环境
    // config.headers.common["token"] = 'b907b7ce-bfb1-4ff0-a614-b4fdb7cb3c8e';
    // 正式
    const UserInfo = sessionStorage.getItem("UserInfo") ? JSON.parse(sessionStorage.getItem("UserInfo")) : null;
    if(UserInfo && UserInfo.Token) {
      config.headers.common["token"] = UserInfo.Token;
    }
    return config;
  },
  error => {
    console.log(error);
  }
);

axios.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.RetCode == '10000') {
      return res;
    } else {
      // Vue.prototype.$toast.fail('res.RetMsg');
      // return Promise.reject(new Error(res.RetMsg || 'Error'));
      return res;
    }
  },
  error => {
    return Promise.reject(error);
  }
)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
