import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navbarIndex: 0, // 底部菜单栏索引
  },
  mutations: {
    changeNavbarIndex(state, index) {
      state.navbarIndex = index;
    }
  },
  actions: {},
  modules: {},
});
