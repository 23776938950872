<template>
  <div class="navbar">
    <span class="navItem" :class="{'active': navbarIndex == index}" v-for="(item, index) in navList" :key="index" @click="navbarChange(item.to)">{{ item.name }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      navList: [{
        name: '首页',
        to: '/index'
      }, {
        name: '成为博主',
        to: '/videoList'
      }, {
        name: '电子相框',
        to: '/electronicPhoto'
      }
      // , {
      //   name: '我的',
      //   to: '/myList'
      // }
      ]
    }
  },
  computed: {
    ...mapState(['navbarIndex'])
  },
  methods: {
    navbarChange(url) {
      this.$router.push(url);
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 0 20px;
  .navItem {
    display: inline-block;
    width: 33.33%;
    text-align: center;
    color: #838383;
    font-size: 15px;
    font-weight: bold;
  }
  .active {
    color: #333333;
  }
}
</style>